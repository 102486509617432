<template>
  <div class="modal-overlay" @click="$emit('close-user-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-lock text-primary me-2" />
        {{ props.user.id != null ? "Edit User" : "New User" }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="userFormRef"
        :model="userForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <el-form-item label="First Name" prop="first_name">
                <el-input v-model="userForm.first_name" />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <el-form-item label="Last Name" prop="last_name">
                <el-input v-model="userForm.last_name" />
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Email" prop="email">
                <el-input v-model="userForm.email" />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <el-form-item label="Phone Number" prop="phone">
                <el-input v-model="userForm.phone" />
              </el-form-item>
            </div>
            <div class="col-md-6 mb-3">
              <el-form-item label="Role" prop="role">
                <el-select-v2
                  v-model="userForm.role"
                  filterable
                  :options="roles"
                  placeholder="Please select a role"
                  style="width: 100%"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-user-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(userFormRef)"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-user-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { createUser, updateUser } from "../../api/services/users";
import { fetchRoles } from "../../api/services/authentication";
import { ElNotification } from "element-plus";
const userFormRef = ref();
const isLoading = ref(false);
const roles = ref([]);
export default {
  name: "add-user",
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const userForm = reactive({
      id: props?.user?.id || 0,
      first_name: props?.user?.first_name || "",
      last_name: props?.user?.last_name || "",
      email: props?.user?.email || "",
      password: props?.user?.password || "",
      phone: props?.user?.phone || "",
      role:
        props?.user?.role != undefined
          ? JSON.stringify(props?.user?.role[0].id)
          : "",
    });

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input your phone number",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      first_name: [
        {
          required: true,
          message: "Please input first name",
          trigger: ["blur", "change"],
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input last name",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input a password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("email", userForm.email);
          formData.append("first_name", userForm.first_name);
          formData.append("last_name", userForm.last_name);
          formData.append("phone", userForm.phone);
          formData.append("password", userForm.password);
          formData.append("role", userForm.role);
          if (userForm.id > 0) {
            delete userForm.password;
            updateUser(userForm)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("close-user-modal");
                  emit("refresh-users");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createUser(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("close-user-modal");
                  emit("refresh-users");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const loadRoles = async () => {
      isLoading.value = true;
      try {
        const response = await fetchRoles();
        const tempRoles = response.data.data.map((role) => ({
          value: `${role.id}`,
          label: `${role.name}`,
        }));
        roles.value = tempRoles;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      loadRoles();
    });
    return {
      loadRoles,
      submitForm,
      rules,
      userForm,
      userFormRef,
      isLoading,
      roles,
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  max-width: 550px;
  max-height: 420px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
